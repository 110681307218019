<template>
  <div class="relative w-full h-full xl:w-[22rem] xl:h-[38rem]">
    <div
      class="absolute bottom-3 right-3 left-3 p-3 bg-v-ui-root-monochrome-0 rounded-lg xl:hidden"
    >
      <span class="text-3xl text-v-ui-text-monochrome font-bold">{{
        slide.title
      }}</span>
    </div>
    <video
      class="w-full h-full object-fill rounded-2xl"
      autoplay
      loop
      muted
      playsinline
    >
      <source src="/videos/doctor-video.webm" type="video/webm" />
      <source src="/videos/doctor-video.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'ConsultationVideoSlide',
  props: {
    slide: {
      type: Object,
      required: true
    }
  }
};
</script>
